"use client";

import { useTheme } from "next-themes";
import React, { ReactNode } from "react";
import Image from "next/image";

const Background = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  const { theme } = useTheme();

  return (
    <div
      className={"h-full relative w-full bg-center bg-no-repeat bg-cover bg-fixed  flex flex-col " + className}
    >
      <Image
        src={theme == "dark" ? "/images/dark-gradient.webp": "/images/gradient.webp"}
        alt="background"
        layout="fill"
        objectFit="cover"
        objectPosition="center"
        priority
      />
      <div className="z-10">{children}</div>
    </div>
  );
};

export default Background;