import * as Yup from "yup";
import YupPassword from "yup-password";
YupPassword(Yup);

declare module "yup" {
  interface StringSchema {
    mobileNumberValidation(errorMessage: string): StringSchema;
  }
}

const isValidMobileNumber = (mobileNumber: string | any[]) => {
  if (mobileNumber.length < 8 || mobileNumber.length > 12) {
    return {
      success: false,
      message: "Mobile Number should of 9 to 11 length",
    };
  } else if (isNaN(Number(mobileNumber))) {
    return {
      success: false,
      message: "Mobile Number should only contain numbers",
    };
  }
  return { success: true, message: "Valid Mobile Number" };
};

Yup.addMethod(Yup.string, "mobileNumberValidation", function (errorMessage) {
  return this.test(`test-mobile-number`, errorMessage, function (value) {
    const { path, createError } = this;
    if (!value) {
      return createError({ path, message: errorMessage });
    }
    const validation = isValidMobileNumber(value);
    return (
      (value && validation.success) ||
      createError({ path, message: validation.message })
    );
  });
});

const signUpValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email().required("E-mail is required"),
  phoneNumber: Yup.string()
    .required("Phone Number is required")
    .mobileNumberValidation("Phone Number is not valid"),
  organisation: Yup.string().required("Organisation name is required"),
  organisationSize: Yup.string().required("Company Size is required"),
  password: Yup.string()
    .password()
    .required("Password is required.")
    .min(8, "Password is too short - should be 8 characters minimum.")
});

const passwordValidationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .password()
    .required("Password is required.")
    .min(8, "Password is too short - should be 8 characters minimum."),
    newPasswordConfirm: Yup.string()
    .password()
    .required("Password is required.")
    .min(8, "Password is too short - should be 8 characters minimum.")
});
const changeEmailValidationSchema = Yup.object().shape({
  password: Yup.string()
    .password()
    .required("Password is required.")
    .min(8, "Password is too short - should be 8 characters minimum."),
});
const waitinglistValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email().required("E-mail is required"),
});
const contactUsValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  note: Yup.string(),
  phoneNumber: Yup.string()
  .required("Phone Number is required")
  .mobileNumberValidation("Phone Number is not valid"),
  email: Yup.string().email().required("E-mail is required"),
});

const emailValidationSchema = Yup.object().shape({
  email: Yup.string().email().required("E-mail is required"),
});

const signInValidationSchema = Yup.object().shape({
  email: Yup.string().email().required("E-mail is required"),
  password: Yup.string()
    .password()
    .required("Password is required.")
    .min(8, "Password is too short - should be 8 characters minimum.")
    .minUppercase(1, "password must contain at least 1 upper case letter"),
});

export {
  emailValidationSchema,
  changeEmailValidationSchema,
  passwordValidationSchema,
  signUpValidationSchema,
  signInValidationSchema,
  waitinglistValidationSchema,
  contactUsValidationSchema
};
