"use client";

import { useRouter } from "next/navigation";
import { CheckmarkCircle24Filled } from "@fluentui/react-icons";

export default function LinkPriceCard({
  name,
  description,
  benefits,
  price,
  isAnnual,
  loading,
  checkoutLink,
}: {
  name: string;
  description: string;
  benefits: string[];
  price: number;
  isAnnual?: boolean;
  loading?: boolean;
  checkoutLink: string;
}) {
  const router = useRouter();

  const submitForm = () => {
    router.push(checkoutLink);
  };

  return (
    <div
      className={`relative w-64 sm:w-80 bg-base-200 rounded-lg p-6 shadow-lg border border-primary/40 ${
        loading ? "animate-pulse h-[20rem]" : ""
      }`}
    >
      <div className="flex flex-col h-full">
        <div className="mb-12 relative">
          {!loading && (
            <p className="absolute top-[-10px] left-[50%] -translate-x-1/2 font-architects-daughter text-sm text-primary text-center">
              $75 off for the next 20 customers{" "}
              <span className="text-secondary">(10 left)</span>
            </p>
          )}

          <h1 className="text-center text-3xl font-inter font-bold pt-12 text-base-content ">
            {name}
          </h1>
          <h2 className="text-center pt-4 text-base-content ">
            {description}
          </h2>
        </div>
        <div className="pb-12">
          <ul className="flex flex-col gap-y-3 mx-12">
            {benefits.map((benefit, i) => (
              <li key={i} className="flex items-center gap-x-4 flex-nowrap">
                <CheckmarkCircle24Filled />
                <p className="w-40 text-base-content overflow-clip text-ellipsis">
                  {benefit}
                </p>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex flex-col mx-auto mt-auto">
          {!loading && (
            <div className="flex flex-row mx-auto gap-x-2 justify-center items-center mb-2">
              <h1 className="text-base-content text-4xl font-bold">
                ${price / 100}
              </h1>
              <p className="w-16 leading-5 text-sm text-base-content ">
                One Time
              </p>
            </div>
          )}
          <button
            onClick={submitForm}
            className="btn btn-primary rounded-full bg-gradient-to-r from-primary to-secondary outline-none border-none capitalize"
          >
            Let&apos;s go!
          </button>
        </div>
      </div>
    </div>
  );
}
