import(/* webpackMode: "eager" */ "/vercel/path0/Frontend/components/Utilities/Background.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/Frontend/node_modules/aos/dist/aos.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/Frontend/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/Frontend/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/Frontend/sections/Features/VerticalTabsFeature.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/Frontend/sections/Hero/WaitingListWithImageHero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/Frontend/sections/LinkPayment.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/Frontend/sections/Video.tsx");
