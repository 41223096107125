"use client";

import React from "react";
import LinkPriceCard from "@/components/LinkPriceCard";

const LinkPayment = ({
  checkoutLink,
}: {
  checkoutLink: string;
}) => {

  return (
    <div className="max-w-6xl mx-auto mb-24 h-full">
      <div className="w-screen lg:w-full pb-4 flex gap-x-4 lg:justify-center gap-y-8 px-6 pt-12 overflow-x-scroll">
            <LinkPriceCard name={"Fast Pocket First"} description={"Want to be on the edge of the PocketBase revolution?"} benefits={["Prebuilt Codebase", "Documentation", "New Newsletter Feature"]} price={7500} checkoutLink={checkoutLink} />
      </div>
    </div>
  );
};

export default LinkPayment;
