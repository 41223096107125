"use client";
import Image from "next/image";
import React, { useState } from "react";

function VerticalTabsFeature() {
  const [tab, setTab] = useState("1");
  return (
    <>
      {/* Features */}
      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div className="relative p-6 md:p-16">
          {/* Grid */}
          <div className="relative z-10 lg:grid lg:grid-cols-12 lg:gap-16 lg:items-center">
            <div className="mb-10 lg:mb-0 lg:col-span-6 lg:col-start-8 lg:order-2">
              <h2 className="text-2xl text-secondary-content font-bold sm:text-3xl ">
                Save 20+ hours of app development with FastPocket
              </h2>
              {/* Tab Navs */}
              <nav
                className="grid gap-4 mt-5 md:mt-10"
                aria-label="Tabs"
                role="tablist"
              >
                <button
                  type="button"
                  className={`hover:bg-base-content/5 ${
                    tab === "1" && "bg-base-300 shadow-lg hover:bg-base-300"
                  } text-start p-4 md:p-5 rounded-xl`}
                  id="tabs-with-card-item-1"
                  aria-controls="tabs-with-card-item-1"
                  role="tab"
                  onClick={() => setTab("1")}
                >
                  <span className="flex">
                    <svg
                      className="flex-shrink-0 mt-2 size-6 md:size-7 text-secondary-content  "
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M5 5.5A3.5 3.5 0 0 1 8.5 2H12v7H8.5A3.5 3.5 0 0 1 5 5.5z" />
                      <path d="M12 2h3.5a3.5 3.5 0 1 1 0 7H12V2z" />
                      <path d="M12 12.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 1 1-7 0z" />
                      <path d="M5 19.5A3.5 3.5 0 0 1 8.5 16H12v3.5a3.5 3.5 0 1 1-7 0z" />
                      <path d="M5 12.5A3.5 3.5 0 0 1 8.5 9H12v7H8.5A3.5 3.5 0 0 1 5 12.5z" />
                    </svg>
                    <span className="grow ms-6">
                      <span className="block text-lg font-semibold text-secondary-content  ">
                        Simple Setup
                      </span>
                      <span className="block mt-1 text-secondary-content ">
                        FastPocket already has a codebase with all of the
                        necessary integrations to start an online business out
                        of the box AND excellent documentation
                      </span>
                    </span>
                  </span>
                </button>
                <button
                  type="button"
                  className={`hover:bg-base-content/5 ${
                    tab === "2" && "bg-base-300 shadow-lg hover:bg-base-300"
                  } text-start p-4 md:p-5 rounded-xl     `}
                  id="tabs-with-card-item-2"
                  aria-controls="tabs-with-card-item-2"
                  role="tab"
                  onClick={() => setTab("2")}
                >
                  <span className="flex">
                    <svg
                      className="flex-shrink-0 mt-2 size-6 md:size-7 text-secondary-content  "
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="m12 14 4-4" />
                      <path d="M3.34 19a10 10 0 1 1 17.32 0" />
                    </svg>
                    <span className="grow ms-6">
                      <span className="block text-lg font-semibold text-secondary-content  ">
                        Copy Paste Components
                      </span>
                      <span className="block mt-1 text-secondary-content ">
                        We give you cookie cutter copy paste setup for your SaaS
                        with unopinionated SaaS styling that can be modified to
                        suite whatever app you are building
                      </span>
                    </span>
                  </span>
                </button>
                <button
                  type="button"
                  className={`hover:bg-base-content/5 ${
                    tab === "3" && "bg-base-300 shadow-lg hover:bg-base-300"
                  } text-start p-4 md:p-5 rounded-xl     `}
                  id="tabs-with-card-item-3"
                  aria-controls="tabs-with-card-item-3"
                  role="tab"
                  onClick={() => setTab("3")}
                >
                  <span className="flex">
                    <svg
                      className="flex-shrink-0 mt-2 size-6 md:size-7 text-secondary-content  "
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
                      <path d="M5 3v4" />
                      <path d="M19 17v4" />
                      <path d="M3 5h4" />
                      <path d="M17 19h4" />
                    </svg>
                    <span className="grow ms-6">
                      <span className="block text-lg font-semibold text-secondary-content  ">
                        Simple Bring Your Own Backend
                      </span>
                      <span className="block mt-1 text-secondary-content ">
                        We offer deployment templates that allow you to deploy
                        to Digital Ocean, Fly.io, Pockethost or any other
                        backend you want to use. You have the control over your
                        hosting
                      </span>
                    </span>
                  </span>
                </button>
              </nav>
              {/* End Tab Navs */}
            </div>
            {/* End Col */}
            <div className="lg:col-span-6">
              <div className="relative">
                {/* Tab Content */}
                <div>
                  {tab == "1" && (
                    <div
                      id="tabs-with-card-1"
                      role="tabpanel"
                      aria-labelledby="tabs-with-card-item-1"
                    >
                      <Image
                        className="shadow-xl shadow-base-200 rounded-xl bg-base-300"
                        src="/images/vertical-tabs-feature-1_520x643.webp"
                        alt="Image Description"
                        width="987"
                        height="1220"
                      />
                    </div>
                  )}
                  {tab == "2" && (
                    <div
                      id="tabs-with-card-2"
                      role="tabpanel"
                      aria-labelledby="tabs-with-card-item-2"
                    >
                      <Image
                        className="shadow-xl shadow-base-200 rounded-xl bg-base-300"
                        src="/images/vertical-tabs-feature-2.webp"
                        alt="Image Description"
                        width="987"
                        height="1220"
                      />
                    </div>
                  )}
                  {tab == "3" && (
                    <div
                      id="tabs-with-card-3"
                      role="tabpanel"
                      aria-labelledby="tabs-with-card-item-3"
                    >
                      <Image
                        className="shadow-xl shadow-base-200 rounded-xl bg-base-300"
                        src="/images/vertical-tabs-feature-3.webp"
                        alt="Image Description"
                        width="987"
                        height="1220"
                      />
                    </div>
                  )}
                </div>
                {/* End Tab Content */}
                {/* SVG Element */}
                <div className="hidden absolute top-0 end-0 translate-x-20 md:block lg:translate-x-20">
                  <svg
                    className="w-16 h-auto text-secondary"
                    width={121}
                    height={135}
                    viewBox="0 0 121 135"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 16.4754C11.7688 27.4499 21.2452 57.3224 5 89.0164"
                      stroke="currentColor"
                      strokeWidth={10}
                      strokeLinecap="round"
                    />
                    <path
                      d="M33.6761 112.104C44.6984 98.1239 74.2618 57.6776 83.4821 5"
                      stroke="currentColor"
                      strokeWidth={10}
                      strokeLinecap="round"
                    />
                    <path
                      d="M50.5525 130C68.2064 127.495 110.731 117.541 116 78.0874"
                      stroke="currentColor"
                      strokeWidth={10}
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
                {/* End SVG Element */}
              </div>
            </div>
            {/* End Col */}
          </div>
          {/* End Grid */}
          {/* Background Color */}
          <div className="absolute inset-0 grid grid-cols-12 size-full">
            <div className="col-span-full lg:col-span-7 lg:col-start-6 bg-base-300 w-full h-5/6 rounded-xl sm:h-3/4 lg:h-full" />
          </div>
          {/* End Background Color */}
        </div>
      </div>
      {/* End Features */}
    </>
  );
}

export default VerticalTabsFeature;
