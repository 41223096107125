import React, { useState } from "react";

import { MediaPlayer, MediaProvider } from "@vidstack/react";
import {
  DefaultAudioLayout,
  defaultLayoutIcons,
  DefaultVideoLayout,
} from "@vidstack/react/player/layouts/default";
import "@vidstack/react/player/styles/base.css";
export type Props = {
  videotitle: string;
  video: string;
  width: number;
  height: number;
};

export default function YouTubeFrame({ video, width, height }: Props) {
  return (
    <MediaPlayer
      className="w-full aspect-video bg-slate-900 bg-base-content font-sans overflow-hidden rounded-xl ring-media-focus data-[focus]:ring-4"
      title="Sprite Fight"
      src="youtube/KCHnP62DWpg"
      controls
    >
      <MediaProvider />
      <DefaultAudioLayout icons={defaultLayoutIcons} />
      <DefaultVideoLayout
        icons={defaultLayoutIcons}
        thumbnails="https://media-files.vidstack.io/thumbnails.vtt"
      />
    </MediaPlayer>
  );
}
