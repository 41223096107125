"use client";

import pb from "@/lib/pocketbase";
import { waitinglistValidationSchema } from "@/utils/form";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";

import Footer from "@/components/Footer";
import Background from "@/components/Utilities/Background";
import { toast } from "react-toastify";
import { title } from "@/constants";
import Logo from "@/components/Logo";

const WaitingListWithImageHero = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(waitinglistValidationSchema),
  });

  const onSubmit = async (data: {
    firstName: string;
    lastName: string;
    email: string;
  }) => {
    try {
      await pb.collection("contact").create({ source: "waitinglist", ...data });
      localStorage.setItem("waitinglist", JSON.stringify(data));
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };
  return (
    <Background>
      <div className="h-screen  w-full bg-clip flex items-center justify-center pt-20">
        <div className="text-center py-8 px-4 m-4 border border-primary/40 sm:px-6 lg:px-8 bg-base-200 rounded-xl shadow-lg">
          {typeof window !== "undefined" &&
          !localStorage.getItem("waitinglist") ? (
            <>
              <h2 className="text-4xl text-base-content font-heading font-black flex-wrap max-w-sm leading-[3.2rem] lg:leading-normal ">
                <span className="bg-primary p-1 text-primary-content">
                  Finish
                </span>{" "}
                Your React + PocketBase project, With A{" "}
                <span className="bg-primary p-1 text-primary-content">
                  Head Start
                </span>
              </h2>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mt-8 space-y-0">
                  <div>
                    <label
                      htmlFor="hs-cover-with-gradient-form-name-1"
                      className="sr-only"
                    >
                      First name
                    </label>
                    <div className="relative">
                      <input
                        {...register("firstName")}
                        type="text"
                        id="hs-cover-with-gradient-form-name-1"
                        className="  py-3 ps-11 pe-4 block w-full bg-base-100/[.03] border-primary/40 placeholder:text-base-content  rounded-lg text-sm focus:border-secondary focus:ring-secondary sm:p-4 sm:ps-11"
                        placeholder="First name"
                      />
                      <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-4">
                        <svg
                          className="flex-shrink-0 size-4 text-base-content  "
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" />
                          <circle cx={12} cy={7} r={4} />
                        </svg>
                      </div>
                    </div>
                    <div className="text-start text-sm italic text-error">
                      {errors.firstName?.message}&nbsp;
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="hs-cover-with-gradient-form-name-1"
                      className="sr-only"
                    >
                      Last name
                    </label>
                    <div className="relative">
                      <input
                        {...register("lastName")}
                        type="text"
                        id="hs-cover-with-gradient-form-name-1"
                        className="  py-3 ps-11 pe-4 block w-full bg-base-100/[.03] border-primary/40 dark:placeholder:bg-base-contentntent  placeholder:text-base-content  rounded-lg text-sm focus:border-secondary focus:ring-secondary sm:p-4 sm:ps-11"
                        placeholder="Last name"
                      />
                      <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-4">
                        <svg
                          className="flex-shrink-0 size-4 text-base-content"
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" />
                          <circle cx={12} cy={7} r={4} />
                        </svg>
                      </div>
                    </div>
                    <div className="text-start text-sm italic text-error">
                      {errors.lastName?.message}&nbsp;
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="hs-cover-with-gradient-form-email-1"
                      className="sr-only"
                    >
                      Email address
                    </label>
                    <div className="relative">
                      <input
                        {...register("email")}
                        type="email"
                        id="hs-cover-with-gradient-form-email-1"
                        className=" py-3 ps-11 pe-4 block w-full bg-base-100/[.03] border-primary/40 text-base-content  placeholder:text-base-content rounded-lg text-sm focus:border-secondary focus:ring-secondary sm:p-4 sm:ps-11"
                        placeholder="Email address"
                      />
                      <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-4">
                        <svg
                          className="flex-shrink-0 size-4 text-base-content  "
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <rect width={20} height={16} x={2} y={4} rx={2} />
                          <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" />
                        </svg>
                      </div>
                    </div>
                    <div className="text-start text-sm italic text-error">
                      {errors.email?.message}&nbsp;
                    </div>
                  </div>
                  <div className="grid">
                    <button
                      className="btn btn-primary text-primary-content"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Join the waitlist
                      {isSubmitting ? (
                        <div className="loading"></div>
                      ) : (
                        <svg
                          className="flex-shrink-0 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="m9 18 6-6-6-6" />
                        </svg>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </>
          ) : (
            <h2 className="sm:text-3xl font-body text-base-content">
              You are on our waiting list. We will be in touch!
            </h2>
          )}
        </div>
      </div>
    </Background>
  );
};

export default WaitingListWithImageHero;
