"use client";

import YouTubeFrame from "@/components/Utilities/YoutubeEmbed";
import React from "react";
import { useState, useEffect } from "react";

function Video() {
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
        {/* Hero image */}
        <div
          className="relative flex justify-center items-center"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <YouTubeFrame
            video="KCHnP62DWpg"
            videotitle="Sign365 - Fill and Sign Once"
            width={
              !!windowWidth && windowWidth > 425
                ? windowWidth > 800
                  ? 800
                  : 400
                : 280
            }
            height={!!windowWidth && windowWidth > 425 ? 500 : 240}
          />
        </div>
      </div>
    </section>
  );
}

export default Video;
